<template>
  <vx-card
    no-shadow
    card-border>
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2 flex items-center md:pr-0">
        <p class="whitespace-no-wrap mr-3">
          {{ $t('$General.Sending') }}
        </p>
        <v-select
          class="w-full"
          label="name"
          v-model="selectedService"
          :multiple="false"
          :closeOnSelect="true"
          :placeholder="$t('$UserSettings.$Shop.$ManageTopUps.$TopUpAccount.ServiceSelectPlaceholder')"
          :options="appServices"
          :clearable="false">
        </v-select>
      </div>
      <div
        v-if="appServiceAvailableCountries.length > 0"
        class="vx-col w-full md:w-1/2 flex items-center mt-3 md:mt-0 md:pl-3">
        <p class="whitespace-no-wrap mr-3">
          {{ $t('$General.To') | lowercase }}
        </p>
        <v-select
          ref="vSelectCountries"
          class="w-full"
          label="name"
          v-model="selectedCountry"
          :multiple="false"
          :closeOnSelect="true"
          :placeholder="$t('$UserSettings.$Shop.$ManageTopUps.$TopUpAccount.CountrySelectPlaceholder')"
          :options="appServiceAvailableCountries"
          :clearable="false">
        </v-select>
      </div>
    </div>
    <vs-divider/>
    <div class="mt-3">
      <small v-html="$t('$UserSettings.$Shop.$ManageTopUps.$TopUpAccount.AboutCreditAndCountriesMsg', {
          url: 'https://www.google.co.ve/?gws_rd=cr&ei=jSVrUpetG83xkQf40YFg',
        })">
      </small>
    </div>
  </vx-card>
</template>

<script>
import selectAppServiceMixin from '@/views/modules/user/_mixins/selectAppService.mixin';
import vSelect from 'vue-select';

/**
 * Mixin to show info about user packages
 *
 * @module views/modules/user-account/components/SelectAppServiceAndCountry
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-computed {string} selectedServiceName - selected service name
 * @vue-event {void} fetchData - fetch ap services to show
 */
export default {
  name: 'SelectAppServiceAndCountry',
  components: {
    vSelect,
  },
  mixins: [selectAppServiceMixin],
  computed: {
    selectedServiceName() {
      return this.selectedService ? this.selectedService.name : '';
    },
  },
  watch: {
    appServicePackages(val) {
      this.$emit('update:packages', val);
    },
    selectedServiceName(val) {
      this.$emit('update:service-name', val);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.getAppServices();

      if (this.appServices.length > 0) {
        [this.selectedService] = this.appServices;
      }
    },
  },
};
</script>
