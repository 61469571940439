<template>
  <vx-card
    no-shadow
    card-border
  >
    <div>
      <div class="flex justify-center items-center">
        <h1 class="font-bold text-center">
          {{ packageInfo.packageAmount | dollar }}
        </h1>
        <small
          v-show="payPeriod === $enums.StripeEnums.Checkout.Mode.SUBSCRIPTION"
          class="whitespace-pre">
          / {{ $t('$General.Month') | lowercase }}
        </small>
      </div>

<!--      <p class="text-center">-->
<!--        <small v-html="$t('$Components.$UserPackageCardInfo.ValidUntil', {-->
<!--          date: this.$options.filters.date(packageInfo.expireIn, true, true, false),-->
<!--        })">-->
<!--        </small>-->
<!--      </p>-->
    </div>

    <vs-divider class="mb-0"/>

    <div>
      <gs-radial-percent-chart
        :percentage="balanceAvailablePercent"/>

      <h5 class="text-center mt-3">
        {{ $t('$Components.$UserPackageCardInfo.YouCanSend', {
        quantity: quantity,
        service: this.$options.filters.uppercase(serviceName),
        }) }}
      </h5>

      <p class="text-grey text-center">
        <small>
          {{ $t('$UserSettings.$Shop.$ManageTopUps.$TopUpAccount.UnitPriceMsg', {
          price: $options.filters.dollar(unitPrice, 3),
        }) }}
        </small>
      </p>
    </div>

    <vs-divider class="mb-0"/>

    <div class="vx-row mt-3 text-center mx-0">
      <div class="vx-col w-full md:w-1/2 d-flex align-items-between flex-column pt-3">
        <p>{{ $t('$General.Spent') }}</p>
        <h3 class="font-weight-bolder mb-50">
          {{ balanceSpent | dollar }}
        </h3>
      </div>

      <div class="vx-col w-full md:w-1/2 d-flex align-items-between flex-column pt-3">
        <p>{{ $t('$General.Available') }}</p>
        <h3 class="font-weight-bolder mb-50">
          {{ packageInfo.balance | dollar }}
        </h3>
      </div>
    </div>
  </vx-card>
</template>

<script>
import enums from '@/enums';
import GsRadialPercentChart from '@/views/charts-and-maps/charts/GsRadialPercentChart.vue';

/**
 * Card info for user packages
 *
 * @module views/modules/components/UserPackageCardInfo
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {Object} packageInfo - user package info
 * @vue-prop {string} serviceName - service name to show
 * @vue-prop {number} quantity - quantity of service that user can send
 * @vue-prop {number} unitPrice - unit price of service
 * @vue-prop {string} payPeriod - pay period of package
 * @vue-computed {number} balanceAvailablePercent - percent available to show
 * @vue-computed {number} balanceSpent - balance spent
 */
export default {
  name: 'UserPackageCardInfo',
  components: {
    GsRadialPercentChart,
  },
  props: {
    packageInfo: {
      type: Object,
      required: true,
    },
    serviceName: {
      type: String,
      required: true,
    },
    quantity: {
      type: Number,
      required: true,
    },
    unitPrice: {
      type: Number,
      required: true,
    },
    payPeriod: {
      type: String,
      required: true,
      validator(val) {
        return [
          enums.StripeEnums.Checkout.Mode.PAYMENT,
          enums.StripeEnums.Checkout.Mode.SUBSCRIPTION,
        ].indexOf(val) !== -1;
      },
    },
  },
  computed: {
    balanceAvailablePercent() {
      const percent = Math.ceil((this.packageInfo.balance * 100) / this.packageInfo.initialAmount);

      return Number.isNaN(percent) ? 0 : percent;
    },
    balanceSpent() {
      return this.packageInfo.initialAmount - this.packageInfo.balance;
    },
  },
};
</script>
