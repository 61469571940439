<template>
  <div>
    <template>
      <div class="vx-row">
        <div class="vx-col w-full">
          <select-app-service
            @update:packages="(val) => packages = val"
            @update:service-name="(val) => serviceName = val"/>
        </div>
      </div>

      <div class="vx-row items-stretch mt-5">
        <div
          v-for="(prepaidPackage, index) in userPrepaidPackages"
          :key="index"
          class="vx-col w-full md:w-1/3 xl:w-1/4 mt-5"
          :class="index > 3 ? 'md:mt-5' : 'md:mt-0'">
          <user-package-card-info
            v-if="prepaidPackage"
            :package-info="prepaidPackage"
            :quantity="getQuantityFromPackage(
          packages[prepaidPackage.packageNumber], prepaidPackage.balance
          )"
            :unit-price="getPriceFromPackage(packages[prepaidPackage.packageNumber])"
            :service-name="serviceName"
            :pay-period="payPeriod"/>
        </div>

        <div
          class="vx-col w-full md:w-1/3 xl:w-1/4 mt-5"
          :class="userPrepaidPackages.length >= 3 ? 'md:mt-5' : 'md:mt-0'">
          <add-new-item-card
            :text="addNewText"
            @add="purchasePackage"
            />
        </div>
      </div>
    </template>

    <package-checkout-modal
      :pay-period="$enums.StripeEnums.Checkout.Mode.PAYMENT"
      :active.sync="showCheckout"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

// Components
import UserPackageCardInfo from '@/views/modules/_components/UserPackageCardInfo.vue';
import SelectAppServiceAndCountry from '@/views/modules/user/_components/SelectAppServiceAndCountry.vue';
import PackageCheckoutModal from '@/views/modules/_components/PackageCheckoutModal.vue';
import AddNewItemCard from '@/views/_components/AddNewItemCard.vue';

// Mixins
import userPackageInfoMixin from '@/views/modules/user/_mixins/userPackageInfo.mixin';

/**
 * User my purchases prepaid packages
 *
 * @module views/modules/user/user-purchases/user-my-purchases/UserMyPurchasesPrepaidPackages
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-computed {string} addNewText - add new item text
 */
export default {
  name: 'UserMyPurchasesPrepaidPackages',
  i18n: {
    messages: {
      en: {
        AddYourFirstPackage: 'Add your first package',
        AddNewPackage: 'Add a new package',
      },
    },
  },
  components: {
    UserPackageCardInfo,
    SelectAppService: SelectAppServiceAndCountry,
    PackageCheckoutModal,
    AddNewItemCard,
  },
  mixins: [userPackageInfoMixin],
  computed: {
    ...mapGetters({
      userPrepaidPackages: 'auth/userPrepaidPackages',
    }),
    addNewText() {
      return this.userPrepaidPackages.length > 0
        ? this.$t('AddNewPackage')
        : this.$t('AddYourFirstPackage');
    },
  },
  created() {
    if (this.$mq === this.$enums.mqBreakpoints.DESKTOP) {
      window.history.replaceState({}, null, '/purchases/my-purchases/my-prepaid-packages');
    }

    this.fetchBalanceInformation();
  },
};
</script>
