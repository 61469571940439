import { mapActions, mapGetters } from 'vuex';
import enums from '@/enums';

/**
 * Mixin to show info about user packages
 *
 * @module views/modules/user-account/mixins/userPackageInfo
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} payPeriod - pay period of packages
 * @vue-data {Object[]} packages - packages to use in showed info
 * @vue-data {string} serviceName - name of service to user in showed info
 * @vue-data {boolean} showCheckout - indicate if show or no the checkout
 * @vue-event {number} getPriceFromPackage - price of package to show
 * @vue-event {number} getQuantityFromPackage - quantity to send of the showed service
 * @vue-event {void} purchasePackage - call to show modal of purchase package
 */
export default {
  props: {
    payPeriod: {
      type: String,
      required: true,
      validator(val) {
        return [
          enums.StripeEnums.Checkout.Mode.PAYMENT,
          enums.StripeEnums.Checkout.Mode.SUBSCRIPTION,
        ].indexOf(val) !== -1;
      },
    },
  },
  data() {
    return {
      packages: [],
      serviceName: '',
      showCheckout: false,
    };
  },
  computed: {
    ...mapGetters({
      userHasPermissionTo: 'auth/userHasPermissionTo',
    }),
    userHasPermissionToBilling() {
      return this.userHasPermissionTo(this.$enums.Auth.Permissions.BUSINESS);
    },
  },
  methods: {
    ...mapActions({
      fetchBalanceInformation: 'auth/fetchBalanceInformation',
    }),
    getPriceFromPackage(appServicePackage) {
      if (appServicePackage) {
        return this.payPeriod === this.$enums.StripeEnums.Checkout.Mode.PAYMENT
          ? appServicePackage.prepaidPrice
          : appServicePackage.monthlyPrice;
      }

      return 0;
    },
    getQuantityFromPackage(appServicePackage, balance) {
      if (appServicePackage) {
        return parseInt(Number(balance / this.getPriceFromPackage(appServicePackage))
          .toFixed(0), 10);
      }

      return 0;
    },
    purchasePackage() {
      this.showCheckout = true;
    },
  },
};
