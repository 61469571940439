<template>
  <dynamic-tabs
    tabs-id="user-purchases-overview-tabs"
    :tabs="tabs"
    :props="{
      payPeriod: $enums.StripeEnums.Checkout.Mode.PAYMENT,
    }"/>
</template>

<script>
import DynamicTabs from '@/views/modules/_components/DynamicTabs.vue';
import UserAccountOverviewPrepaidPackages from '@/views/modules/user/user-purchases/user-my-purchases/UserMyPurchasesPrepaidPackages.vue';
import UserAccountOverviewDedicatedNumbers
from '@/views/modules/user/user-purchases/user-my-purchases/my-dedicated-numbers/UserMyPurchasesDedicatedNumbers.vue';

/**
 * User my purchases tabs
 *
 * @module views/modules/user/user-purchases/user-my-purchases/UserMyPurchasesTabs
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {number} [tabActive=0] - active tab index
 * @vue-data {Array<Object>} [tabs=[...]] - tabs to show
 */
export default {
  name: 'UserAccountOverview',
  components: {
    DynamicTabs,
  },
  data() {
    return {
      tabActive: 0,
      tabs: [
        {
          icon: 'icon-grid',
          label: this.$t('$UserSettings.$Shop.$MyPurchases.$PrepaidPackages.TabTitle'),
          component: UserAccountOverviewPrepaidPackages,
          path: 'my-prepaid-packages',
        },
        {
          icon: 'icon-smartphone',
          label: this.$t('$UserSettings.$Shop.$MyPurchases.$DedicatedNumbers.TabTitle'),
          component: UserAccountOverviewDedicatedNumbers,
          path: 'my-dedicated-numbers',
        },
      ],
    };
  },
};
</script>
